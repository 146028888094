<template>
   <div>
      <v-card elevation="0" color="#e3f2fd80" height="60" width="100%" style="border-radius: 0 0 100px 100px;" />
      <div class="d-flex align-center flex-column grey--text text-center" style="height: calc(100vh - 60px - 60px);">
         <div>
            <v-img v-if="$store.state.setting.global.Logo" :src="setUrlFromImage($store.state.setting.global.Logo)" contain width="96" class="mx-auto" style="margin-top: 60px;" />
            <v-img v-else :src="require('@/assets/logo.svg')" contain width="84" class="mx-auto" style="margin-top: 60px; filter: invert(22%) sepia(43%) saturate(3402%) hue-rotate(189deg) brightness(65%) contrast(99%);" />
            <p class="text-uppercase text-h6 primary--text mt-3">
               Assistance
            </p>
         </div>
         <div class="my-auto">
            <!-- v-if="activity.step === 0" -->
            <v-form ref="form" @submit.prevent="login">
               <v-text-field v-model="form.credential" label="Enter your credential" placeholder="Member ID or Email" outlined required :rules="[v => !!v || 'Credential is required']" autofocus />
               <v-text-field v-model="form.password" label="Enter your password" placeholder="Password" outlined required :rules="[v => !!v || 'Password not blank']"
                  :type="seen?'text':'password'" :append-icon="seen?'mdi-eye':'mdi-eye-off'"
                  @click:append="seen=!seen"
                  />
               <v-btn large dark color="primary" depressed type="submit" class="mt-3">
                  Login
               </v-btn>
            </v-form>
         </div>
         <p class="mt-auto">
            {{ `${$store.state.setting.global.title} v${version.slice(0, 3)}.${version.slice(3, 4)}.${version.slice(-1)}` }}
         </p>
      </div>
      <v-card elevation="0" color="#e3f2fd80" height="60" width="100%" style="border-radius: 100px 100px 0 0;" />
   </div>
</template>

<script>
import { version } from '../../../package'
import { imageFromUrl, flatten, encrypt } from '@/utils/nirtara'
export default {
   name: 'NLoginForm',

   props: {
      activity: { type: Object },
      operator: { type: Object },
   },
   data: () => ({
      isDebugging: false,
      version: version.replace(/\D/g, '').slice(-5),
      seen: false,
      form: { credential: '', password: '' },
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   created () {
   },
   mounted () {
      // Object.assign(this.form, { credential: 'nvm@50.web', password: 'nvm50web' })
      this.isDebugging && Object.assign(this.form, { credential: 'rcp@rcp.rcp', password: 'rcp' })
   },
   methods: {
      login: async function () {
         if (!this.$refs.form.validate()) return
         const { form } = this
         if (form.credential === 'bypass' && form.password === 'nopass') {
            this.activity.step = 2
         } else {
            var data = {
               conditions: {
                  kind: 'Member',
                  credential: form.credential,
                  password: encrypt(form.password),
               },
               headers: { command: 'signinApproval' },
            }
            // console.log('selectRecord (data)', JSON.parse(JSON.stringify(data)))
            const response = await this.$store.dispatch('mongoose', data)
            // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
            if (response.data.online) {
               const json = response.data
               Object.assign(this.operator, flatten(json))
               this.activity.step = 2
            }
         }
      },
   },
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--large {
   height: 44px;
   min-width: 150px;
}
</style>
